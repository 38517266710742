/* Contact */

.contact {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100vh;
  overflow-y: hidden;
  width: 100%;
  margin-left: 8%;
  padding-top: 20vh;
}

#email {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 40px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .contact {
    margin-left: 0;
    height: 100%;
  }
  #email {
    font-size: 1.3rem;
    height: 125px;
    padding: 10px;
  }
}