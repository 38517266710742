/* Side component */

.side-bar {
    position: fixed;
    background: rgb(241,90,36);
    background: linear-gradient(180deg, rgba(241,90,36,1) 39%, rgba(251,176,59,1) 100%);
    width: 8%;
    height: 100vh;
    text-orientation: sideways;
    z-index: 100;
  }
  
  .side-bar .side-title {
    transform: rotate(-90deg);
    position: relative;
    bottom: -70%;
    user-select: none;
  }
  
  .side-bar .side-title h1{
    font-family: 'champions';
    display: inline;
    overflow: visible;
    padding-left: 5px;
    font-size: 60px;
    letter-spacing: 5px;
  }
  
  .side-text-red {
   color: rgb(156, 42, 0);
   
  }
  
  .side-text-yellow {
    color: #FBB03B;
  }

  @media only screen and (max-width: 600px) {
    .bar {
      display: block;
      background: rgb(241,90,36);
      background: linear-gradient(90deg,rgba(251,176,59,1) 39%, rgba(241,90,36,1) 100%);
      width: 100%;
      height: 40px;
    }
  
    .title h1 {
      display: inline !important;
      letter-spacing: 5px;
      font-size: 30px;
    }
  
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .side-bar {
      display: none;
    }
  }

  @media only screen and (max-width: 750px) {
    .side-title h1 {
      font-size: 3rem !important;
    }
  }
  
  @media only screen and (min-width: 412px) {
    .title h1 {
      display: none;
    }
  }