/* Us */

.us {
  min-height: 80vh;
  max-height: fit-content;
  width: 100%;
  padding-left: 8%;
  padding-top: 25vh;
  .bryden-info {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: fit-content;
    padding-bottom: 30px;
    .img-bryden {
      height: 300px;
      width: 100%;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        overflow: hidden;
      }
    }
    .bryden-text {
      padding: 15px;
      line-height: 25px;
      height: fit-content;
      #band-leader {
        color: #F15A24;
      }
      #trumpet {
        color: #FBB03B;
      }
    }
  }
}
  
.abraham-info {
  display: grid;
  grid-template-columns: 2fr 1fr;
  min-height: 300px;
  max-height: fit-content;
  .abraham-text {
    padding: 15px;
    margin: 0px 25px 0px 25px;
    line-height: 40px;
    border-top: 4px solid;
    border-image: linear-gradient(to right, orange, red) 2;
  }
  .img-abraham {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    .us-abraham {
      padding-top: 30px;
      font-family: 'champions';
      font-size: 40px;
      z-index: 90;
      position: absolute;
      text-transform: uppercase;
    }
    img {
      width: 90%;
      overflow: hidden;
      position: absolute;
      bottom: -20%;
      transform: scaleX(-1);
    }
  }
}

.mobile-about {
  display: none;
  overflow-y: scroll;
}

#root {
  background-color: #000000;
}

li::marker {
  text-align: end !important;
}

// Mobile version

.mobile-about {
  .mobile-text{
    position: absolute;
    z-index: 770;
  }
  .about-bg {
    top: 25vh;
    position: fixed;
  }
  h5 {
    font-family: 'champions', sans-serif;
    font-size: 50px;
  }
  section {
    height: auto;
    display: flex;
    width: 100%;
    z-index: 777;
    overflow-y: scroll;
    &:nth-child(1) {
      margin-bottom: 80px;
    }
    .content {
      font-size: 18px;
      padding: 30px 30px 30px 30px;
      width: 100%;
      overflow-x: visible;
      color: white;
      #band-leader {
        color: #F15A24;
      }
      #trumpet {
        color: #FBB03B;
      }
      .space {
        height: 10vh;
      }
    }
  }
}

// Responsive

@media only screen and (max-width: 430px) {
  .bryden-info {
    display: none !important;
  }

  .abraham-info {
    display: none !important;
  }

  .mobile-about {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .us {
    padding-left: 0;
    padding-top: 25vh;

    .bryden-info {
      display: grid;
      grid-template-columns: 1fr;
      height: fit-content;
      padding-left: 0;
    }

    .abraham-info {
      display: grid;
      grid-template-columns: 1fr;
      height: fit-content;
      padding-top: 2% !important;
      .abraham-text {
        border: none;
      }
      .img-abraham {
        grid-row: 1;
        order: 1;
        .us-abraham {
          padding-left: 20px;
        }
      }
    }

    section .content h5 {
      font-family: 'champions', sans-serif;
      font-size: 50px;
    }
  
    section .content #yellow-surname {
      padding-bottom: 40px;
    }

    #band-leader {
      color: #F15A24;
    }

    #trumpet {
      color: #FBB03B;
    }
  }
}