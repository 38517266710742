/* Navbar */
nav {
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    background-color: rgb(255, 255, 255);
    height: 20vh;
    z-index: 888;
    margin: 0 auto;
    justify-content: space-between;
    justify-content: center;
  }
  
  .nav-links {
    display: flex;
    justify-content: space-between;
    list-style: none;
    text-transform: uppercase;
    font-family: 'champions';
    font-size: 2.9rem;
    font-weight: 400;
    align-items: center;
    width: 70%;
    user-select: none;
  }
  
  .circle {
    overflow: visible;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circle:hover {
    background-color:black;
    border-radius: 100%;
    color: #FBB03B;
    transition: 0.2s ease-in;
  }

  .link-active {
    color: #FBB03B;
  }

  #logo-div {
    padding-right: 40px;
    
    img {
      width: 120px;
      height: 120px;
    }

    .mobile-logo {
      width: 30%;
      height: initial;
    }
  }

  @media only screen and (max-height: 500px) {
    #logo-div img {
      width: 60px;
      height: 60px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    nav {
      height: 21vh;
      display: flex;
      flex-direction: column;
    }

    .nav-links {
      font-size: 22px;
      background: rgb(251,176,59);
      background: linear-gradient(90deg, rgba(251,176,59,1) 0%, rgba(241,90,36,1) 100%);
      width: 100%;
      padding: 0px 30px 0px 30px;
      border-top: 10px solid black;
      margin-top: 0;
      justify-content: space-evenly !important;
      li {
        color: black;
      }
    }
  
    #logo-div {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      min-height: 175px;
      max-width: fit-content;
      width: 100%;
      text-align: center;
      padding-right: 0 !important;
    }
  
    .circle {
      width: 20px;
      height: 25px;
      color: white;
    }
  
    .circle:hover {
      background-color: transparent;
    }
  }
