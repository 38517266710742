@font-face {
  font-family: "champions";
  src: url('/Champion.otf');
}

p {
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}

a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

h4 {
  font-weight: 400;
  font-size: 45px;
  text-transform: uppercase;
}

h5 {
  font-weight: 400;
  font-size: 40px;
  text-transform: uppercase;
}

ul {
  text-align: end;
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}

.us-names {
  font-family: 'champions';
  position: absolute;
  font-size: 50px;
  right: 25px;
  text-transform: uppercase;
}

#yellow-name {
  color: #FBB03B;
}

#white-surname {
  color: white;
}

#orange-name {
  color: #F15A24;
}

#yellow-surname {
  color: #FBB03B;
  padding-bottom: 20px;
}

span {
  color: white;
  font-family: 'Lato', sans-serif;
}