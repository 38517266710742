.transition {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    touch-action: none;
    overflow: hidden;
    width: 100vw;
    height: 150vh;
    z-index: 999;
    overflow: hidden;
    background-color: hsla(0, 0%, 0%, 0.427);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.music-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;

}

.loader-line {
    width: 30px;
    height: 4px;
    background-color: #000000;
    animation: musicloader 1.5s ease-in-out infinite;
}
  
@keyframes musicloader {
0% {
    height: 4px;
}
50% {
    height: 600px;
}
100% {
    
}
}

.loader-line:nth-child(1) {
    animation-delay: 1s;
}

.loader-line:nth-child(2) {
    animation-delay: 0.8s;
}

.loader-line:nth-child(3) {
    animation-delay: 0.6s;
}

.loader-line:nth-child(4) {
    animation-delay: 0.4s;
}

.loader-line:nth-child(5) {
    animation-delay: 0.2s;
}

.loader-line:nth-child(6) {
    animation-delay: 0.2s;
}

.loader-line:nth-child(7) {
    animation-delay: 0.4s;
}

.loader-line:nth-child(8) {
    animation-delay: 0.6s;
}

.loader-line:nth-child(9) {
    animation-delay: 0.8s;
}

.loader-line:nth-child(10) {
    animation-delay: 1s;
}

@media only screen and (max-width: 600px) {
    .music-loader {
        display: none;
    }

    .transition {
        background-color: black;
    }

    .logo-transition {
        height: 100vh;
        width: 100%;
        position: fixed;
        display: flex;
        justify-content: center;
        padding-top: 10vh;
        z-index: 999;
    }
}

@media only screen and (min-width: 600px){
    .logo-transition {
        display: none;
    }
}

