/* Global */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
  
body {
    background-color: black;
    height: 100%;
}